import moment from 'moment';
import { map, reduce, flatten, groupBy, prop, without } from 'ramda';
import { useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import type {
  Query,
  BaseEntity,
  ReduxState,
  EntityIdentifier,
  EntityReduxState,
  FileUploadState,
  RequestState,
} from '../../../types';
import {
  createShallowEqualSelector,
  makeSelectRequestState,
  makeSelectCreateRequestState,
  selectEntityData,
} from '../selectors';
import { useEntityActions } from './useEntityActions';

export * from './useEntityActions';
export * from './useEntityFilters';
export * from './useFetchRequestProvider';
export * from './useFetchEntity';

type NestEntry = {
  entity?: EntityIdentifier;
  key: string;
  getKey?: (entry?: any) => number | string | null | undefined;
  nest?: NestDefinition;
  collect?: boolean;
};

export type NestDefinition = Array<NestEntry>;

function setNested(
  value: null | undefined,
  {
    entity,
    key,
    nest,
  }: { entity: EntityIdentifier; nest: NestDefinition; key: any },
  selection: Record<EntityIdentifier, EntityReduxState>
): undefined;
function setNested<T>(
  value: Array<string | number>,
  {
    entity,
    key,
    nest,
  }: { entity: EntityIdentifier; nest: NestDefinition; key: any },
  selection: Record<EntityIdentifier, EntityReduxState>
): T[];
function setNested<T>(
  value: string | number,
  {
    entity,
    key,
    nest,
  }: { entity: EntityIdentifier; nest: NestDefinition; key: any },
  selection: Record<EntityIdentifier, EntityReduxState<T>>
): T;
function setNested<T extends Record<string, unknown>>(
  value: null | undefined | Array<string | number> | string | number,
  {
    entity,
    key,
    nest,
  }: { entity: EntityIdentifier; nest: NestDefinition; key: any },
  selection: Record<EntityIdentifier, EntityReduxState<T>>
): T | T[] | undefined {
  if (value === null || value === undefined) return undefined;
  if (Array.isArray(value)) {
    return value
      .map((entryId) => {
        return setNested<T>(entryId, { entity, key, nest }, selection);
      })
      .filter((entry) => entry !== undefined);
  }

  if (typeof value === 'object') {
    return processNest<T>(value, nest, selection);
  }

  const entry = selection[entity].entities[`${value}`];

  // TODO: find a better way to reference functions
  // eslint-disable-next-line no-use-before-define
  return entry ? processNest<T>(entry, nest, selection) : entry || undefined;
}

// TODO: @ts-imddata
export function processNest<T extends Record<string, unknown>>(
  entry: T | undefined,
  nest: NestDefinition | undefined,
  selection: Record<EntityIdentifier, EntityReduxState<T>>
): T | undefined {
  if (!nest) return entry;
  if (!entry) return undefined;
  const cache: T = { ...entry };

  for (let i = 0; i < nest.length; i += 1) {
    const data = nest[i];
    const value = data.getKey
      ? data.getKey(entry)
      : entry
        ? entry[data.key]
        : null;

    // @ts-ignore
    cache[data.key] = setNested(value, data, selection);
  }
  return cache;
}

// TODO: @ts-imddata
function processMissingNest(
  value: any,
  { collect, collectQuery, entity, key, nest }: any,
  selection: any
): any {
  if (value === null || value === undefined) return [];

  if (Array.isArray(value)) {
    return value
      .map((entryId) => {
        return processMissingNest(
          entryId,
          { entity, key, nest, collect, collectQuery },
          selection
        );
      })
      .filter((entry) => entry !== undefined);
  }
  if (typeof value === 'object') {
    return collectMissingNested(value, nest, selection);
  }

  const entry = selection[entity].entities[value];

  return !entry && collect
    ? [{ entity, id: value, query: collectQuery }]
    : // TODO: find a better way to reference functions
    // eslint-disable-next-line no-use-before-define
    collectMissingNested(entry, nest, selection);
}

// TODO: @ts-imddata
function collectMissingNested(entry: any, nest: any, selection: any) {
  return nest
    ? nest.reduce(
      (acc: any, data: any) => [
        ...acc,
        ...processMissingNest(
          data.getKey ? data.getKey(entry) : entry ? entry[data.key] : null,
          data,
          selection
        ),
      ],
      []
    )
    : [];
}

// TODO: @ts-imddata
function assembleMissingEntities(value: any, nest: any, selection: any) {
  const missing = Array.isArray(value)
    ? flatten(
      value.map((entry) => {
        return collectMissingNested(entry, nest, selection);
      })
    )
    : flatten(collectMissingNested(value, nest, selection));
  return missing && missing.length ? groupBy(prop('entity'), missing) : null;
}

// TODO: @ts-imddata
const collectedNestedEntities = (
  state: ReduxState,
  nest: NestDefinition = [],
  cache: Record<string, any> = {}
): Record<string, EntityReduxState> => {
  return nest
    ? nest.reduce((acc, data) => {
      if (data.entity) {
        const entityData = selectEntityData(state, data.entity);
        acc[data.entity] = entityData;
      }
      return data.nest
        ? collectedNestedEntities(state, data.nest, cache)
        : acc;
    }, cache)
    : {};
};

type EntrySelector<T> = {
  (entityData: any, id?: string | number): T | undefined;
};

const defaultSelectEntry: EntrySelector<BaseEntity> = (
  entityData: EntityReduxState,
  id?: string | number
) => {
  if (!id) return undefined;
  const entry = entityData?.entities[id];
  if (!entry) return undefined;

  if (entry.temporary && entry.created)
    return entityData.entities[entry.created];

  return entry;
};

const makeSelectEntryState = <T>(missingOnly: boolean) => {
  return createShallowEqualSelector(
    [
      (
        state: ReduxState,
        {
          selectEntry,
          id,
          entity,
        }: {
          selectEntry: typeof defaultSelectEntry | EntrySelector<T>;
          id?: string | number;
          entity: EntityIdentifier;
        }
      ) => {
        const entityData = selectEntityData(state, entity);
        return selectEntry(entityData, id);
      },
      (state: ReduxState, { nest }: { nest?: NestDefinition }) => {
        return collectedNestedEntities(state, nest);
      },
      (_, { nest }: { nest?: NestDefinition }) => nest,
    ],
    (entry, nested, nest) => {
      if (!entry) return { entry: null, missing: null };

      return {
        entry: missingOnly
          ? null
          : // @ts-ignore
          { ...entry, ...processNest(entry, nest, nested) },
        missing: missingOnly
          ? assembleMissingEntities(entry, nest, nested)
          : null,
      };
    }
  );
};

const PAUSE_RESULT = { entry: undefined };

export function useEntryProvider<T>({
  pauseSelector,
  entity,
  id,
  nest,
  selectEntry = defaultSelectEntry,
}: {
  selectEntry?: typeof defaultSelectEntry | EntrySelector<T>;
  nest?: NestDefinition;
  id: string | number | undefined;
  entity: EntityIdentifier;
  pauseSelector?: boolean;
}): T | undefined {
  if (!entity) {
    throw new Error('Accessing undefined entity');
  }
  const selectEntryState = useMemo(() => makeSelectEntryState<T>(false), []);

  const input = useMemo(
    () => ({ id, entity, nest, selectEntry }),
    [id, entity, nest, selectEntry]
  );

  const result = useSelector((state: ReduxState) => {
    if (pauseSelector) return PAUSE_RESULT;

    return selectEntryState(state, input);
  });

  // @ts-ignore
  return result.entry;
}

export function useEntryMissingProvider({
  entity,
  id,
  nest,
  selectEntry = defaultSelectEntry,
}: {
  entity: EntityIdentifier;
  id?: string | number;
  nest?: NestDefinition;
  // @ts-ignore
  selectEntry?: EntrySelector;
}) {
  if (!entity) {
    throw new Error('Accessing undefined entity');
  }
  const selectEntryState = useMemo(() => makeSelectEntryState(true), []);
  const input = useMemo(
    () => ({ id, entity, nest, selectEntry }),
    [id, entity, nest, selectEntry]
  );

  const result = useSelector((state: ReduxState) =>
    selectEntryState(state, input)
  );

  return result.missing;
}

const attachNest =
  ({ nest, nested, entities }: any) =>
    (key: any) => {
      const entry = entities[key];
      return processNest(entry, nest, nested);
    };

type RemoveDeleted = <T extends { deletedFromApi: boolean }>(
  entities: Record<string, T>
) => (acc: string[], id: string) => string[];

const removeDeleted: RemoveDeleted = (entities) => (acc, id) =>
  entities[id].deletedFromApi ? acc : [...acc, id];

type GroupTitleEntry = {
  key: string;
  type: 'group-title';
  ids: Array<string | number>;
};

const makeNewItemGroup = <T>({
  newItemsKeys,
  groups = [],
  entities,
  nest,
  nested,
}: any): Array<T | GroupTitleEntry> => {
  const filteredItems =
    newItemsKeys && newItemsKeys.length
      ? groups.reduce(
        // @ts-ignore
        (acc, nextGroup) => without(nextGroup.items, acc),
        newItemsKeys.reduce(removeDeleted(entities), [])
      )
      : [];

  return filteredItems.length
    ? [
      {
        key: 'new-group',
        type: 'group-title',
        ids: filteredItems,
      },
      ...filteredItems.map(attachNest({ nest, nested, entities })),
    ]
    : [];
};

// TODO: move to state or delete or simplify
type Group = any;

const makeGroups = <T>({
  groups,
  newItemsKeys,
  entities,
  nest,
  nested,
}: any): Array<Array<T | GroupTitleEntry>> => {
  // @ts-ignore
  return reduce(
    (acc, group: Group) => [
      ...acc,
      {
        id: group.value,
        title: moment(group.value).isValid()
          ? moment(group.value).format('LL')
          : group.label,
        type: 'group-title',
        ids: group.items.reduce(removeDeleted(entities), []),
      },
      ...group.items
        .reduce(removeDeleted(entities), [])
        .map(attachNest({ nest, nested, entities })),
    ],
    makeNewItemGroup({ newItemsKeys, groups, entities, nest, nested }),
    groups
  );
};

const makeSelectEntriesState = () =>
  createShallowEqualSelector(
    [
      (state: ReduxState, { entity }: { entity: EntityIdentifier }) => {
        return selectEntityData(state, entity);
      },
      (state: ReduxState, { nest }: { nest?: NestDefinition }) => {
        return collectedNestedEntities(state, nest);
      },
      (_, { nest }: { nest?: NestDefinition }) => nest,
      (_, { queryHash }: { queryHash?: string }) => queryHash,
      (_, { ids }: { ids?: Array<string> }) => ids,
    ],
    (entityData, nested, nest, queryHash, ids) => {
      if (queryHash && !entityData?.searchHistory) {
        throw new Error(
          'Attempting to access entity without searchHistory support'
        );
      }
      const keys =
        ids ||
        (queryHash
          ? entityData.searchHistory[queryHash]?.keys || []
          : entityData.keys);

      const groupKeys = queryHash
        ? entityData.searchHistory[queryHash]?.groups
        : entityData.groups;

      const { entities } = entityData;
      const entityIds: Array<string> = keys
        ? keys.reduce((acc, key) => {
          if (!entities) return acc;
          return entities[key]?.temporary && entities[key]?.created
            ? [...acc, `${entities[key]?.created}`]
            : [...acc, key];
        }, Array<string>())
        : [];
      const entriesForMissing = entityIds
        .map((key) => {
          return entities[key];
        })
        .filter(Boolean);

      const entries = entityIds
        .map((key) => {
          return attachNest({ nest, nested, entities })(key);
        })
        .filter(Boolean)
        .filter((e) => !e.deletedFromApi);

      return {
        missing: assembleMissingEntities(entriesForMissing, nest, nested),
        keys,
        entities: entities
          ? // @ts-ignore
          map((data) => processNest(data, nest, nested), entities)
          : {},
        groups: groupKeys
          ? makeGroups({
            nest,
            nested,
            entities,
            groups: groupKeys,
            newItemsKeys: entityData.newItemsKeys,
          })
          : [],
        entries,
      };
    }
  );

const none = {
  entries: [],
  keys: [],
  missing: null,
  groups: [],
  entities: {},
};
const selectNone = () => none;

export function useEntriesProvider<T>({
  ids,
  entity,
  queryHash,
  nest,
  passive,
}: {
  ids?: Array<string | number>;
  entity: EntityIdentifier;
  nest?: NestDefinition;
  queryHash?: string;
  passive?: boolean;
}): {
  missing: any;
  keys: Array<string | number>;
  entities: Record<string | number, T>;
  groups: Array<Array<T>>;
  entries: Array<T>;
} {
  const selectEntriesState = useMemo(
    () => (passive ? selectNone : makeSelectEntriesState()),
    [passive]
  );
  const input = useMemo(
    () => ({ queryHash, ids, entity, nest }),
    [ids, queryHash, entity, nest]
  );

  const result = useSelector((state: ReduxState) =>
    // @ts-ignore
    selectEntriesState(state, input)
  );

  // @ts-ignore
  return result;
}

export function useAllAvailableEntries<T>({
  entity,
  nest,
  passive,
}: {
  entity: EntityIdentifier;
  nest?: NestDefinition;
  passive?: boolean;
}): Array<T> {
  const selectEntriesState = useMemo(
    () => (passive ? selectNone : makeSelectEntriesState()),
    [passive]
  );
  const input = useMemo(() => ({ entity, nest }), [entity, nest]);

  const result = useSelector((state: ReduxState) =>
    // @ts-ignore
    selectEntriesState(state, input)
  );

  // @ts-ignore
  return useMemo(
    () =>
      Object.values(result.entities)
        .filter(Boolean)
        // @ts-ignore
        .filter((e) => !e.deletedFromApi && !e.isDeleted),
    [result.entities]
  );
}

const fetchedExtracted: [keyof RequestState, ...(keyof RequestState)[]] = [
  'fetched',
];
type RequestHookOptions = {
  entity: EntityIdentifier;
  id?: number | string;
  queryHash?: string;
};
export function useFetchTimestampProvider({
  entity,
  id,
  queryHash,
}: RequestHookOptions): number | undefined {
  const selectFetchRequest = useMemo(
    () => makeSelectRequestState(fetchedExtracted),
    []
  );

  const result = useSelector((state: ReduxState) =>
    selectFetchRequest(state, {
      entity,
      id,
      queryHash,
    })
  );

  return result?.fetched;
}

const updateRequestExtractedKeys: [
  keyof RequestState,
  ...(keyof RequestState)[],
] = ['updating', 'updated', 'failed', 'errorMessage', 'errorData', 'errors'];

export function useUpdateRequestProvider({
  entity,
  id,
  queryHash,
}: RequestHookOptions) {
  const selectFetchRequest = useMemo(
    () => makeSelectRequestState(updateRequestExtractedKeys),
    []
  );
  return useSelector((state: ReduxState) =>
    selectFetchRequest(state, {
      entity,
      id,
      queryHash,
    })
  );
}

const deleteRequestExtractedKeys: [
  keyof RequestState,
  ...(keyof RequestState)[],
] = [
    'deleting',
    'deleted',
    'failed',
    'deleteErrorMessage',
    'errorData',
    'deleteErrors',
  ];

export function useDeleteRequestProvider({
  entity,
  id,
  queryHash,
}: RequestHookOptions) {
  const selectFetchRequest = useMemo(
    () => makeSelectRequestState(deleteRequestExtractedKeys),
    []
  );
  return useSelector((state: ReduxState) =>
    selectFetchRequest(state, {
      entity,
      id,
      queryHash,
    })
  );
}

const baseFileUpload: FileUploadState = {
  progress: -1,
  failed: false,
  uploading: false,
  uploaded: false,
};

const fileUploadExtractKey: [keyof RequestState, ...(keyof RequestState)[]] = [
  'fileUpload',
];

export function useFileUploadRequestProvider({
  entity,
  id,
  queryHash,
}: RequestHookOptions) {
  const selectFetchRequest = useMemo(
    () => makeSelectRequestState(fileUploadExtractKey),
    []
  );

  const fileUpload = useSelector((state: ReduxState) => {
    const request = selectFetchRequest(state, {
      entity,
      id,
      queryHash,
    });
    return request?.fileUpload || baseFileUpload;
  });

  return fileUpload;
}

export function useEntityFileRequestProvider({
  entity,
  id,
  queryHash,
}: {
  entity: 'releases' | 'tracks';
  id?: RequestHookOptions['id'];
  queryHash?: RequestHookOptions['queryHash'];
}) {
  const fileUpload = useFileUploadRequestProvider({ entity, id, queryHash });

  const inProgress = useSelector((state: ReduxState) => {
    const uploadStatus = id
      ? state.entities[entity].entities[id]?.uploadStatus
      : '';

    return uploadStatus === 'initialized' || uploadStatus === 'confirmed';
  });

  return useMemo(() => {
    return {
      inProgress,
      ...fileUpload,
    };
  }, [fileUpload, inProgress]);
}

const makeSelectIsUploadingTracks = () =>
  createShallowEqualSelector(
    [
      (state: ReduxState, ids: Array<number>) => {
        if (!ids || !ids.length) return [false];
        const items = state.requests.entities.tracks?.items;

        return ids.reduce((acc, trackId) => {
          return {
            [trackId]:
              items[trackId]?.updating || items[trackId]?.fileUpload?.uploading,
            ...acc,
          };
        }, {});
      },
    ],
    (trackMap) => {
      Object.values(trackMap).reduce((acc, val) => acc || val, false);
    }
  );

export function useIsUploadingTracks({ ids }: { ids: Array<number> }) {
  const selectIsUploadingTracks = useMemo(makeSelectIsUploadingTracks, []);

  return useSelector((state: ReduxState) => {
    return selectIsUploadingTracks(state, ids);
  });
}

export function useCreateRequestProvider({
  entity,
  requestStoreKey,
}: {
  entity: EntityIdentifier;
  requestStoreKey?: string;
}) {
  const selectCreateRequestState = useMemo(makeSelectCreateRequestState, []);
  const request = useSelector((state: ReduxState) =>
    selectCreateRequestState(state, { entity, requestStoreKey })
  );
  return request;
}

export function useDeleteEntity({
  entity,
  query,
  queryHash,
  id,
}: {
  entity: EntityIdentifier;
  query?: Query;
  queryHash?: string;
  id?: string | number;
}) {
  const request = useDeleteRequestProvider({ entity, queryHash, id });

  const entityActions = useEntityActions(entity);
  const deleteEntry = useCallback(
    (
      params: Partial<Parameters<typeof entityActions.remove>[0]> = {},
      meta?: Parameters<typeof entityActions.remove>[1]
    ) => {
      entityActions.remove(
        {
          id,
          query,
          queryHash,
          ...params,
        },
        meta
      );
    },
    [id, queryHash, query, entity]
  );

  return { request, deleteEntry };
}

export function useUpdateEntity({
  entity,
  id,
  debounce,
  queryHash,
  query,
}:
  | {
    debounce?: boolean;
    entity: EntityIdentifier;
    id: string | number;
    queryHash?: undefined;
    query?: Query;
  }
  | {
    debounce?: boolean;
    entity: EntityIdentifier;
    queryHash?: string;
    id?: undefined;
    query?: Query;
  }) {
  const request = useUpdateRequestProvider({ entity, queryHash, id });
  const entityActions = useEntityActions(entity);

  const updateEntry = useCallback(
    (params = {}, meta = {}) => {
      entityActions.update(
        {
          id,
          query,
          queryHash,
          ...params,
        },
        debounce ? { ...meta, debounce: true } : meta
      );
    },
    [id, queryHash, query, entity]
  );

  return { request, updateEntry };
}

let createEntityCounter = 0;

const createDefaultKey = () => {
  createEntityCounter += 1;
  return `${Date.now()}-${createEntityCounter}`;
};

export function useCreateEntity({
  entity,
  requestStoreKey,
  componentKey,
  query,
}: {
  entity: EntityIdentifier;
  requestStoreKey?: string;
  componentKey?: string;
  query?: Query;
}) {
  const [fallbackKey, setKey] = useState('stubkey');

  const requestStoreKeyFinal = requestStoreKey || componentKey || fallbackKey;

  const request = useCreateRequestProvider({
    entity,
    requestStoreKey: requestStoreKeyFinal,
  });

  const entityActions = useEntityActions(entity);

  const createEntry = useCallback(
    (params = {}, meta = undefined) => {
      const key = requestStoreKey || componentKey || createDefaultKey();
      setKey(key);
      entityActions.create(
        {
          query,
          componentKey: key,
          ...params,
        },
        meta
      );
    },
    [requestStoreKeyFinal, query, entity]
  );

  return {
    request,
    createdId: request.id,
    requestStoreKey: requestStoreKeyFinal,
    createEntry,
  };
}
