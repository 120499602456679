import React from 'react';
import styled from '@emotion/styled';
// @ts-ignore
import background from './background.mp4';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledVideo = styled.video`
  z-index: -1;
  top: 0;
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  filter: contrast(0.25);
`;

export const VideoBackground: React.FC = ({ children }) => (
  <Container>
    <Wrapper>
      <StyledVideo autoPlay={true} playsInline={true} muted={true} loop={true}>
        <source src={background} type="video/mp4" />
      </StyledVideo>
    </Wrapper>

    {children}
  </Container>
);
