import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Translation } from 'react-i18next';
import {
  ScreenLayout,
  AppIcon,
  LanguageSwitcher,
  TextFormatted,
  Block,
} from 'imdshared';
import styled from '@emotion/styled';
import { VideoBackground } from 'imdui';

const RootStyled = styled.div`
  width: 100%;
  position: relative;
`;

const WrapperStyled = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 20px auto;
`;
const AppIconWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
`;

const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const components = {};

const TermsWrapper: React.FC = ({ children }) => (
  <VideoBackground>
    <ScreenLayout components={components}>
      <RootStyled>
        <AppIconWrapper>
          <Block margin={[30, 10, 30, 10]}>
            <AppIcon />
          </Block>
        </AppIconWrapper>

        <WrapperStyled>
          <FlexWrapper>{children}</FlexWrapper>
        </WrapperStyled>
      </RootStyled>
    </ScreenLayout>
  </VideoBackground>
);

const Terms = () => (
  <Translation>
    {(t) => (
      <TermsWrapper>
        <Helmet>
          <title>{t('page-title-terms-of-use')}</title>
        </Helmet>

        <div style={{ width: '100%' }}>
          <Block card={true} padding={[70, 50, 50, 50]}>
            <LanguageSwitcher
              style={{ position: 'absolute', top: 20, right: 20 }}
            />

            <TextFormatted
              style={{ overflowY: 'auto' }}
              text={t('terms-of-use-text')}
            />
          </Block>
        </div>
      </TermsWrapper>
    )}
  </Translation>
);

export default Terms;
