//
import React, { useContext } from 'react';
import styled from '@emotion/styled';
import {
  Card,
  AppContext,
  Block,
  Subheading,
  AppIcon,
  CenterWrapper,
  palette,
} from 'imdshared';
import { VideoBackground } from 'imdui';

const StyledCenterWrapper = styled(CenterWrapper)`
  padding: 0 8px;
`;

const StyledCard = styled(Card)`
  max-width: 480px;
  margin: 72px auto 0 auto;

  @media (max-width: 375px) {
    margin: 8px 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 32px 24px 24px;
`;

const StyledAppIcon = styled(AppIcon)`
  margin-right: 16px;
`;

const FormWrapper = ({
  children,
}: {
  loggedIn: boolean;
  children: React.ReactChild;
}) => {
  const app = useContext(AppContext);

  return (
    <VideoBackground>
      <StyledCenterWrapper maxContentWidth={448}>
        <StyledCard>
          <Header>
            <StyledAppIcon />
            <Subheading>{app === 'admin' ? 'Admin Panel' : ''}</Subheading>
          </Header>

          <Block backgroundColor={palette.light.xLight}>{children}</Block>
        </StyledCard>
      </StyledCenterWrapper>
    </VideoBackground>
  );
};

export default FormWrapper;
