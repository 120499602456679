import styled from '@emotion/styled';
import type { PaymentMethod } from './types';

export const PaymentMethodContainer = styled.div<{
  selectionEnabled: boolean;
  id: PaymentMethod;
  focusedPaymentMethod?: PaymentMethod;
  confirmedPaymentMethod: PaymentMethod | null;
}>`
  background: var(--surface-container-medium);

  border-radius: 8px;
  width: 100%;
  display: ${({
  focusedPaymentMethod,
  selectionEnabled,
  id,
  confirmedPaymentMethod,
}) =>
    (selectionEnabled && id === focusedPaymentMethod) ||
      (confirmedPaymentMethod && confirmedPaymentMethod === id)
      ? 'grid'
      : 'none'};
  grid-template-columns: 1fr max-content;
  gap: 8px;
  align-items: center;
  margin-top: 12px;
  padding: 24px;
  margin-bottom: ${({ selectionEnabled }) => (selectionEnabled ? 0 : '32px')};
  & > div {
    min-width: 0;
  }
`;
