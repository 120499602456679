import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { Button } from 'imdui';
import { Window, ConfirmationContent } from 'imdshared';
import { CallRequestWindow } from 'components';

const DeleteAccountButton: React.FC = () => {
  const { t } = useTranslation();
  const [windowType, setWindowType] = useState<string | null>(null);
  const [redirectToWarningPage, setRedirectToWarningPage] = useState(false);

  const windowProps = useMemo<
    React.ComponentProps<typeof ConfirmationContent> & { title: string }
  >(() => {
    switch (windowType) {
      case 'confirmation':
        return {
          onRequestClose: () => {
            setWindowType(null);
          },
          title: t('delete-account'),
          message: t('delete-account-confirmation-message'),
          confirmLabel: t('confirm-account-deletion'),
          onConfirm: () => {
            setWindowType('callrequest');
          },
        };
      case 'callrequest':
        return {
          buttonType: true,
          title: t('call-request'),
          message: t('call-request-message'),
          confirmLabel: t('yes'),
          onRequestClose: () => {
            setWindowType(null);
          },
          cancelLabel: t('no'),
          onCancel: () => {
            setRedirectToWarningPage(true);
          },
          onConfirm: () => {
            setWindowType('callform');
          },
        };
      case 'callform':
        return {
          title: t('call-request'),
          onRequestClose: () => {
            setWindowType(null);
          },
        };
      default:
        return {
          title: '',
          onRequestClose: () => {
            setWindowType(null);
          },
        };
    }
  }, [t, windowType]);

  const onRequestClose = () => {
    setWindowType(null);
  };

  const handleClickDelete = useCallback(() => {
    setWindowType('confirmation');
  }, []);

  return (
    <>
      {redirectToWarningPage && <Redirect to="/account/profile/delete" />}

      <Button
        appearance="transparent"
        onClick={handleClickDelete}
        text={t('delete-account')}
      />

      <Window
        isOpen={windowType !== null}
        title={windowProps.title}
        close={onRequestClose}
      >
        {windowType !== 'callform' ? (
          <ConfirmationContent {...windowProps} />
        ) : (
          <CallRequestWindow
            subject="customer-deletion"
            onRequestClose={onRequestClose}
          />
        )}
      </Window>
    </>
  );
};

export default DeleteAccountButton;
