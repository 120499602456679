import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';
import { nil, type EntityModels } from 'imddata';

import { Content, BodySmall, Button, HelperText } from 'imdui';
import AdyenCardGateway from './AdyenCardGateway';
import PaypalGateway from './PaypalGateway';
import { footerStyle } from '../../../shared';
import type {
  PaymentMethodData,
  PaymentMethod,
  GatewayProps,
  PaymentData,
  OnPaymentChange,
} from './types';
import { ApplicationSettingsContext } from 'imdshared';

const CreditGateway = ({ onPaymentChange }: GatewayProps) => {
  useEffect(() => {
    onPaymentChange({
      paymentMethod: nil,
      id: nil,
      isReady: true,
    });
  }, []);
  const { t } = useTranslation();
  return (
    <div>
      <Content>{t('credit')} </Content>
    </div>
  );
};
const SelectMethodRow = styled.div`
  ${footerStyle}
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  ${BodySmall} {
    margin-bottom: 24px;
  }
`;

const getPaymentProcessorComponent = (
  paymentMethod: PaymentMethod
): React.FC<GatewayProps> => {
  switch (paymentMethod) {
    case nil:
      return CreditGateway;
    case 'card':
      return AdyenCardGateway;
    case 'paypal':
      return PaypalGateway;
    default: {
      throw new Error('not supported payment gate');
    }
  }
};
export const PaymentProcessor = ({
  currencyId,
  paymentData: pdProp,
  paymentMethod,
  onSelect,
  onChange,
  errorText,
  paymentMethodData = {
    data: { originKey: undefined, allowSocialSecurityNumber: false },
  },
  selectionEnabled,
  customerPaymentMethods = [],
}: {
  customerPaymentMethods?: Array<EntityModels.CustomerPaymentMethod>;
  currencyId?: string;
  paymentData?: PaymentData;
  paymentMethodData: PaymentMethodData;
  selectionEnabled: boolean;
  paymentMethod: PaymentMethod;
  errorText?: string;
  onSelect?: (pm: PaymentMethod, data: PaymentData) => void;
  onChange?: (data: PaymentData) => void;
}) => {
  const { t } = useTranslation();
  const { numberFormatLocale } = useContext(ApplicationSettingsContext);
  const Processor = useMemo(
    () => getPaymentProcessorComponent(paymentMethod),
    [paymentMethod]
  );

  const [paymentData, setPaymentData] = useState<PaymentData>(
    () =>
      pdProp || { id: `new-${paymentMethod}`, paymentMethod, isReady: false }
  );
  useEffect(() => {
    if (pdProp) {
      setPaymentData(pdProp);
    }
  }, [pdProp]);
  const onPaymentChange = useCallback<OnPaymentChange>((data) => {
    setPaymentData(data);
  }, []);

  const onConfirm = useCallback(() => {
    if (onSelect) {
      onSelect(paymentMethod, paymentData);
    }
  }, [paymentData]);

  useEffect(() => {
    if (onChange) {
      onChange(paymentData);
    }
  }, [paymentData]);

  const {
    fee,
    data: { originKey, allowSocialSecurityNumber, enforceStorePaymentMethod },
  } = paymentMethodData;

  const formData = {
    enforceStorePaymentMethod,
    originKey,
    allowSocialSecurityNumber,
  };

  const disabled =
    !paymentData.isReady || paymentMethod !== paymentData.paymentMethod;

  const priceFormatter = useMemo(() => {
    if (currencyId) {
      return new Intl.NumberFormat(numberFormatLocale, {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: currencyId,
      });
    }
    return {
      format: () => '',
    };
  }, [currencyId]);

  return (
    <>
      <Processor
        customerPaymentMethods={customerPaymentMethods}
        selectionActive={selectionEnabled}
        paymentData={paymentData}
        formData={formData}
        onPaymentChange={onPaymentChange}
      />

      {errorText && (
        <div>
          <HelperText errorText={errorText} />
        </div>
      )}
      {selectionEnabled ? (
        <SelectMethodRow>
          {enforceStorePaymentMethod && (
            <BodySmall>
              {t('your-payment-details-will-be-stored-for-future-renewals')}
            </BodySmall>
          )}
          {fee && (
            <BodySmall>
              <Trans
                // @ts-ignore
                tOptions={{ context: paymentMethod }}
                i18nKey="payment-method-fee"
              >
                Note that {paymentMethod} will charge you with additional fee{' '}
                {priceFormatter.format(fee)}. You will still be able to review
                your order after proceeding.
              </Trans>
            </BodySmall>
          )}
          {onSelect && (
            <Button
              primary={!disabled}
              appearance="fill"
              style={{ width: '100%' }}
              testId={
                disabled ? 'SelectMethod--disabled' : 'SelectMethod--enabled'
              }
              text={t('select')}
              disabled={disabled}
              onClick={onConfirm}
            />
          )}
        </SelectMethodRow>
      ) : null}
    </>
  );
};
