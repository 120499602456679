import { camelize } from 'humps';
import { useFeature } from 'imdfeature';
import moment from 'moment';
import { groupBy, map, filter, sort, view, lensPath, head } from 'ramda';
import React, {
  useContext,
  useRef,
  useEffect,
  useState,
  useMemo,
  useCallback,
  createContext,
} from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { v1 as uuid } from 'uuid';
import type {
  EntityModels,
  EntityReduxState,
  EntityIdentifier,
  Query,
  ReduxState,
  Settings,
  CustomerFeautre,
} from '../../types';

import type { SubscriptionId } from '../../types/entities';
import { ImpersonateContext } from '../contexts/ImpersonateContext';
import {
  useEntryMissingProvider,
  useEntityActions,
  useFetchEntity,
  useCreateEntity,
  useEntriesProvider,
  useEntryProvider,
  useFileUploadRequestProvider,
} from './base';
import type { NestDefinition } from './base';
import { useMissing } from './useMissing';
import { useQuery } from './useQueryHash';

const fallbackFeauters: never[] = [];

export const useIsAdmin = () => {
  return useSelector((state: ReduxState) => state.auth.profile?.isAdmin);
};

export const useIsAdminImpersonating = () => {
  return useSelector(
    (state: ReduxState) => state.auth.profile?.customerId !== null
  );
};

export const useDevmode = () => {
  return useSelector((state: ReduxState) => state.dev.devmode);
};

export const useCustomerFeature = (feature: CustomerFeautre) => {
  const isAdmin = useIsAdmin();
  const isImpersonating = useIsAdminImpersonating();

  const enabledFeatures = useSelector(
    ({ auth }: ReduxState) =>
      auth.profile?.extra?.featuresEnabled || fallbackFeauters
  );

  if (isAdmin && !isImpersonating) {
    return true;
  }

  return !!enabledFeatures.find((f) => f === feature);
};

export const useCustomerFeatureRollout = ({
  feature,
  rolloutKey,
  fallback,
}: {
  feature: CustomerFeautre;
  rolloutKey: string;
  fallback: boolean;
}) => {
  const enabled = useCustomerFeature(feature);
  const [rolledOut] = useFeature({ featureKey: rolloutKey });
  if (!rolledOut) {
    return fallback;
  }
  return enabled;
};

export const useSettings = () => {
  const emptySettings = useMemo<Settings>(() => ({}), []);
  const { update } = useEntityActions('settings');
  const currentSettings: Settings = useSelector(
    (state: ReduxState) => state.entities.settings?.data || emptySettings
  );
  const updateSettings = useCallback(
    (settings: Settings = {}) =>
      update({ data: { ...currentSettings, ...settings } }),
    []
  );

  return {
    updateSettings,
    settings: currentSettings,
  };
};

export const useCurrentAdmin = () => {
  return useSelector((state: ReduxState) => state.auth.profile?.admin);
};
export const useIsTestEnv = () => {
  return useSelector((state: ReduxState) => state.dev?.devmode === 'ci');
};

type UseEntryOptions = {
  query?: Query;
  id?: string | number;
  storeId?: string | number;
  entity: EntityIdentifier;
  nest?: NestDefinition;
  pauseOnMissing?: boolean;
  passive?: boolean;
};

export function useEntry<T>({
  query,
  id,

  storeId,
  entity,
  nest,
  pauseOnMissing,
  passive,
}: UseEntryOptions) {
  const nonCollideQueryHashCompute = useMemo(
    () => (query ? { ...query, HOOK_KEY: 'useEntry' } : undefined),
    [query]
  );
  const { queryHash } = useQuery({ query: nonCollideQueryHashCompute });
  const { updateLocalEntry } = useEntityActions(entity);
  const { request, reload, refresh } = useFetchEntity({
    queryHash,
    entity,
    id,
    query,
    isSingle: true,
    passive,
  });

  const missing = useEntryMissingProvider({
    entity,
    id: storeId || id,
    nest,
  });

  const missingRequest = useMissing({
    missing,
    passive,
    meta: { origin: { entity, id, query } },
  });

  const entry = useEntryProvider<T>({
    pauseSelector: !passive && missingRequest.pause && pauseOnMissing,
    entity,
    id: storeId || id,
    nest,
  });

  // const finalRequest = useMemo(
  //   () => ({
  //     ...request,
  //     loading: request.loading || loading,
  //     loaded: loaded && (request.loaded || failed), // We don't want to rely on all requests to API be successful for  missing assets
  //   }),
  //   [loaded, failed, loading, request]
  // );

  return {
    nestLoadFinshed: missingRequest.loaded || missingRequest.failed,
    missing,
    updateLocalEntry,
    refresh,
    entry,
    reload,
    // request: finalRequest,
    request,
  };
}

type UseEntriesOptions = {
  query?: Query;
  ids?: Array<string | number>;
  entity: EntityIdentifier;
  nest?: NestDefinition;
  passive?: boolean;
  queryHash?: string;
};

export function useEntries<T>({
  ids,
  query,
  queryHash,
  nest,
  entity,
  passive,
}: UseEntriesOptions) {
  const { request, reload, loadMore, refresh } = useFetchEntity({
    ids,
    entity,
    query,
    queryHash,
    passive,
  });

  const { entries, missing, groups } = useEntriesProvider<T>({
    ids,
    entity,
    nest,
    queryHash,
  });

  const missingRequest = useMissing({
    missing,
    passive,
    meta: { origin: { entity, ids, query, queryHash } },
  });

  // const finalRequest = useMemo(
  //   () => ({
  //     ...request,
  //     loading: request.loading || loading,
  //     loaded: loaded && (request.loaded || failed), // We don't want to rely on all requests to API be successful for  missing assets
  //   }),
  //   [loaded, loading, failed, request]
  // );

  return {
    nestLoadFinshed: missingRequest.loaded || missingRequest.failed,
    reload,
    groups,
    entries,
    // request: finalRequest,
    request,
    loadMore,
    refresh,
  };
}

const defaultSelectOverview =
  (requestStoreKey: string) => (entityData: EntityReduxState) => {
    return requestStoreKey
      ? entityData.indexedData && entityData.indexedData[requestStoreKey]
      : entityData.data?.data;
  };

export function useCreateOrder({
  entity,
  requestStoreKey: requestStoreKeyPassed,
  id,
}: {
  entity: EntityIdentifier;
  requestStoreKey?: string;
  id?: string | number;
}) {
  const { createEntry, requestStoreKey, request } = useCreateEntity({
    entity: 'createOrder',
    requestStoreKey: requestStoreKeyPassed,
  });

  // @ts-ignore
  const orderId = request?.id?.item; // TODO figure how this is working

  const finalRequest = useMemo(
    () => ({ ...request, id: orderId }),
    [orderId, request]
  );

  const createOrder = useCallback(
    (
      data?: Parameters<typeof createEntry>[0]['data'],
      meta?: Parameters<typeof createEntry>[1]
    ) => {
      createEntry(
        {
          id,
          ...data,
          query: { productType: entity, ...(data?.query || {}) },
        },
        meta
      );
    },
    [entity, id, createEntry]
  );

  return {
    request: finalRequest,
    requestStoreKey,
    createOrder,
  };
}

export function useCreateTakedown({
  entity,
  requestStoreKey: requestStoreKeyPassed,
  id,
}: {
  entity: 'customers' | 'deliveryBundles';
  requestStoreKey: string;
  id: number | string;
}) {
  const { createEntry, requestStoreKey, request } = useCreateEntity({
    entity: 'createTakedown',
    requestStoreKey: requestStoreKeyPassed,
  });

  const createTakedown = useCallback(
    ({ handler } = {}, meta) => {
      createEntry(
        {
          id,
          query: { entity, handler },
        },
        meta
      );
    },
    [entity, id, createEntry]
  );

  return {
    request,
    requestStoreKey,
    createTakedown,
  };
}

const selectData = (entityData: EntityReduxState) => entityData.data;

type Product = EntityModels.ProductItem & { groupId: string };

type DependantPrice = {
  dependencies?: {
    pricePlanId?: string[];
  };
  discount: EntityModels.DiscountPrice;
};

type CustomerPrices = {
  result: {
    pricePlans: string[];
    codependentProducts: DependantPrice[];
    forcedPricePlan: string | null;
    defaultPricePlan: string;
  };
  entities: {
    pricePlans: Record<string, EntityModels.PricePlan>;
    products: {
      subscriptions: { items: SubscriptionId[] };
      buyableProducts: { items: string[] };
    };
    productItems: Record<string, Product>;
  };
};

export function useCustomerArtistLimits() {
  const artistLimits = useSelector((state: ReduxState) => {
    const subscriptionLimits = state.auth.profile?.extra?.subscription?.limits;
    if (!subscriptionLimits) return null;

    return subscriptionLimits.find((sl) => sl.name === 'artists');
  });
  return artistLimits;
}

export function useCustomerSubscriptionState() {
  const passive = useSelector(
    (state: ReduxState) => !state.auth.profile?.customerId
  );

  const { request, refresh } = useFetchEntity({
    passive,
    entity: 'customerSubscriptionState',
  });

  const data = useSelector((state: ReduxState) => {
    return state.entities.customerSubscriptionState.data;
  });

  return { data, request, refresh };
}

export function useCustomerPrices() {
  const passive = useSelector(
    (state: ReduxState) => !state.auth.profile?.customerId
  );

  const { request } = useFetchEntity({
    passive,
    entity: 'pricesCustomer',
  });
  // TODO: refactor selectEntry types to something more meaningful or useEntryProvider to useDataProvider or smth
  // @ts-ignore
  const entry = useEntryProvider<CustomerPrices>({
    selectEntry: selectData,
    entity: 'pricesCustomer',
  });

  return { data: entry, request };
}

export function useCustomerPricePlan() {
  const passive = useSelector(
    (state: ReduxState) => !state.auth.profile?.customerId
  );

  const { request } = useFetchEntity({
    passive,
    entity: 'pricesCustomer',
  });
  // TODO: refactor selectEntry types to something more meaningful or useEntryProvider to useDataProvider or smth
  // @ts-ignore
  const entry = useEntryProvider<CustomerPrices>({
    selectEntry: selectData,
    entity: 'pricesCustomer',
  });

  const pricePlanId =
    entry?.result?.forcedPricePlan || entry?.result?.defaultPricePlan;

  const pricePlan = pricePlanId
    ? entry?.entities.pricePlans[pricePlanId]
    : undefined;

  return { entry: pricePlan, request };
}

export function useCustomerProducts() {
  const { data: prices, request } = useCustomerPrices();
  const output = useMemo(() => {
    if (!prices || !prices.result) return null;

    const { defaultPricePlan, forcedPricePlan } = prices.result;
    const plan = forcedPricePlan || defaultPricePlan;
    const { products, productItems } = prices.entities;

    if (!request.loaded || !products.buyableProducts) {
      return { ungroupped: [] };
    }

    return groupBy(
      (entry) => (entry.groupId ? camelize(entry.groupId) : 'ungroupped'),
      map<Product, Product & { defaultPrice: EntityModels.Price }>(
        (product) => ({
          ...product,
          defaultPrice: product.prices[plan],
        }),
        filter(
          (p) => !!p.availability[camelize(plan) as EntityModels.PricePlanId],
          map<string, Product>(
            (productId) => productItems[productId],
            products.buyableProducts.items
          )
        )
      )
    );
  }, [prices]);

  return {
    request,
    products: output,
  };
}

export function useCustomerProduct(id?: string) {
  const { data: prices, request } = useCustomerPrices();

  const product = useMemo(() => {
    if (prices && prices.result && id) {
      return prices.entities.productItems[id];
    }
    return null;
  }, [prices, id]);
  return {
    product,
    request,
  };
}

export function useCustomerAmpBalance() {
  const { amps } = useCurrentCustomerBalance();

  return amps;
}

export function useAmpPrice(id: string) {
  const data = useCustomerProduct(id);
  if (!data?.product?.price?.ampPrice) return null;
  return Number(data.product.price.ampPrice);
}

export function useProductPrice(
  product: EntityModels.ProductId | '' = '',
  subscriptionId?: EntityModels.SubscriptionId | 'none'
) {
  const sub = useCurrentCustomerSubscription();
  const { data: prices, request } = useCustomerPrices();

  const price = useMemo(() => {
    if (prices && prices.result) {
      if (subscriptionId) {
        return view(
          lensPath([
            'entities',
            'productItems',
            product,
            'pricesBySubscription',
            subscriptionId,
          ]),
          prices
        );
      }
      return prices.entities.productItems[product].price;
    }
    return null;
  }, [prices, product, sub?.subscriptionId]);
  return {
    price,
    request,
  };
}

type Calendar = {
  // TODO:
  firstAvailable: { date: string };
  dates: Record<string, { isAvailable: boolean }>;
};

export const useCalendar = () => {
  const { request } = useFetchEntity({
    entity: 'deliveryDates',
  });
  // TODO: refactor selectEntry types to something more meaningful or useEntryProvider to useDataProvider or smth
  // @ts-ignore
  const entry = useEntryProvider<Calendar>({
    selectEntry: selectData,
    entity: 'deliveryDates',
  });

  return { data: entry, request };
};

export const useDeliveryDates = () => {
  return useCalendar();
};

type UseOverviewOptions = {
  query?: Query;
  id?: string | number;
  entity: EntityIdentifier;
  nest?: NestDefinition;
  queryHash?: string;
  componentKey?: string;
  data?: Record<string, unknown>;
  selectOverview?: typeof defaultSelectOverview;
};

export function useOverview<T = EntityModels.Overview>({
  entity,
  selectOverview = defaultSelectOverview,
  data,
  id,
  componentKey,
  query,
  nest,
}: UseOverviewOptions) {
  const {
    createEntry,
    requestStoreKey,
    request: { errorData, errorMessage, creating, failed, created },
  } = useCreateEntity({
    entity,
    componentKey,
  });

  const initialData = useRef(data);

  useEffect(() => {
    if (data !== initialData.current || (!creating && !created && !failed)) {
      createEntry({ id, data });
      initialData.current = data;
    }
  }, [query, creating, data, created, id]);

  const selectEntry = useMemo(
    () => selectOverview(requestStoreKey),
    [requestStoreKey]
  );

  const overview = useEntryProvider<T>({
    entity,
    selectEntry,
    id,
    nest,
  });

  const request = useMemo(
    () => ({
      errorMessage,
      errorData,
      loading: creating,
      loaded: created,
      failed,
    }),
    [errorData, errorMessage, creating, failed, created]
  );

  const refresh = useCallback(
    (
      props?: Parameters<typeof createEntry>[0],
      meta?: Parameters<typeof createEntry>[1]
    ) => {
      createEntry({ id: props?.id || id, data, ...props }, meta);
    },
    [id]
  );

  return {
    requestStoreKey,
    refresh,
    overview,
    request,
  };
}

export function useBundleOverview({
  id,
  componentKey,
}: {
  id: number;
  componentKey?: string;
}) {
  const { request, refresh, overview } = useOverview({
    componentKey,
    id,
    entity: 'deliveryBundleOverview',
  });

  return {
    request,
    refresh,
    overview,
  };
}

const nestOrdersOverview: NestDefinition = [
  {
    key: 'includedOrders',
    entity: 'orders',
  },
];

export type OrdersOverviewNested = EntityModels.Nest<
  EntityModels.OrdersOverview,
  {
    includedOrders: EntityModels.Order[];
  }
>;

export function useOrdersOverview({
  data,
  voucherId,
  componentKey,
  id,
}: {
  voucherId?: string;
  data?: Record<string, unknown>;
  componentKey?: string;
  id?: string;
} = {}) {
  const query = useMemo(() => ({ voucherId }), [voucherId]);

  const { request, refresh, overview } = useOverview<OrdersOverviewNested>({
    componentKey,
    data,
    id,
    entity: 'ordersOverview',
    selectOverview: defaultSelectOverview,
    query,
    nest: nestOrdersOverview,
  });

  return {
    request,
    refresh,
    overview,
  };
}

const nestSalesStatements: NestDefinition = [
  {
    key: 'months',
    entity: 'salesStatements',
  },
];

type SalesStatement = EntityModels.Nest<
  EntityModels.SalesStatement,
  {
    months: EntityModels.SalesStatement[];
  }
>;

export function useSalesStatements() {
  return useEntries<SalesStatement>({
    entity: 'salesStatements',
    nest: nestSalesStatements,
  });
}

type Diffable = {
  year: number;
  month: number;
};

type DiffStatement = (a: Diffable, b: Diffable) => number;

const diffYear: DiffStatement = function (a, b) {
  return b.year - a.year;
};
const diffMonth: DiffStatement = function (a, b) {
  return b.month - a.month;
};

export function useSalesStatementsRanges() {
  const { entries } = useSalesStatements();
  const months = useMemo(() => {
    if (!entries) return [];
    return sort(diffYear, entries).reduce<{ value: string; text: string }[]>(
      (acc, { year, months }) => [
        ...acc,
        ...sort(diffMonth, months).reduce<{ value: string; text: string }[]>(
          (monthAcc, { month }) => [
            ...monthAcc,
            {
              value: `${year}-${month}`,
              text: moment()
                .year(year)
                .month(month - 1)
                .format('YYYY MMMM'),
            },
          ],
          []
        ),
      ],
      []
    );
  }, [entries]);

  const years = useMemo(() => {
    if (!entries) return [];
    return sort(diffYear, entries).reduce<{ value: string; text: string }[]>(
      (acc, { year }) => [
        ...acc,
        {
          value: `${year}`,
          text: moment().year(year).format('YYYY'),
        },
      ],
      []
    );
  }, [entries]);

  const [statementRange, setStatementRange] = useState([]);

  const [from, to] = statementRange;

  const handleChange = useCallback((event, range) => {
    setStatementRange(range);
  }, []);

  useEffect(() => {
    if (entries?.length) {
      handleChange(null, [head(months)?.value, head(months)?.value]);
    }
  }, [!!entries?.length]);

  const handleFromChange = useCallback(
    (newFrom) => {
      if (moment(newFrom).isAfter(moment(to))) {
        handleChange(null, [newFrom, newFrom]);
      } else {
        handleChange(null, [newFrom, to]);
      }
    },
    [to]
  );

  const handleToChange = useCallback(
    (newTo) => {
      if (moment(newTo).isBefore(moment(from))) {
        handleChange(null, [newTo, newTo]);
      } else {
        handleChange(null, [from, newTo]);
      }
    },
    [from]
  );

  const filterValue = from || to ? from + (to !== from ? `,${to}` : '') : null;

  return {
    months,
    years,
    statementRange,
    handleFromChange,
    handleChange,
    handleToChange,
    filterValue,
  };
}

const nestCountry: NestDefinition = [
  {
    key: 'country',
    entity: 'countries',
  },
  {
    key: 'subscription',
    entity: 'customerSubscriptions',
    nest: [
      {
        key: 'subscription',
        entity: 'subscriptions',
      },
    ],
  },
];

export type CustomerNested = EntityModels.Nest<
  EntityModels.Customer,
  {
    country: EntityModels.Country;
    subscription: EntityModels.Nest<
      EntityModels.CustomerSubscription,
      { subscription: EntityModels.Subscription }
    >;
  }
>;

const makeCurrentCustomerBalance = () =>
  createSelector(
    (state: ReduxState) => {
      if (!state.auth.profile?.customerId) return undefined;

      return state.entities.customers.entities[state.auth.profile?.customerId];
    },
    (customer) => ({
      isUserCustomer: !!customer,
      amps: customer?.credits?.find((c) => c.currencyId === 'amp')?.amount,
      hasConversion: customer
        ? customer.cashCreditCurrency !== customer.localizedCashCreditCurrency
        : false,
      cashCreditValue: customer ? customer.localizedCashCreditFormatted : '',
      promoCreditValue: customer ? customer.promoCreditFormatted : '',
    })
  );

export function useCurrentCustomerBalance() {
  const selector = useMemo(() => makeCurrentCustomerBalance(), []);

  return useSelector(selector);
}
export function useCurrentCustomer() {
  const id = useSelector((state: ReduxState) => {
    const { auth: loggedUser } = state;
    const customerId = loggedUser.data ? loggedUser.data.customerId : undefined;
    return customerId;
  });
  return useEntry<CustomerNested>({
    id,
    passive: id === undefined,
    nest: nestCountry,
    entity: 'customers',
  });
}

export function useCurrentCustomerHasAddress() {
  return useSelector((state: ReduxState) => {
    const customerId = state.auth?.data?.customerId;
    if (!customerId) return false;
    const customer = state.entities.customers.entities[customerId];
    return customer?.hasAddressSet;
  });
}

export function useCurrentCustomerSubscription() {
  const customerSubscription = useSelector((state: ReduxState) => {
    const { auth: loggedUser } = state;
    const customerId = loggedUser.data ? loggedUser.data.customerId : undefined;
    if (!customerId) return null;
    const customerSubscriptionId =
      state.entities.customers.entities[customerId]?.subscription;
    if (!customerSubscriptionId) return null;
    return state.entities.customerSubscriptions.entities[
      customerSubscriptionId
    ];
  });
  const subscription = useSelector((state: ReduxState) => {
    if (!customerSubscription) return null;
    return state.entities.subscriptions.entities[
      customerSubscription.subscriptionId
    ];
  });
  return useMemo(
    () =>
      !customerSubscription
        ? undefined
        : { ...customerSubscription, subscription: subscription },
    [subscription, customerSubscription]
  );
}

export function useCustomerSalesCommission() {
  const { entry: customerPricePlan } = useCustomerPricePlan();

  const subscription = useCurrentCustomerSubscription();

  const salesCommission =
    subscription?.subscription?.salesCommission !== undefined
      ? subscription?.subscription?.salesCommission
      : customerPricePlan?.salesCommission;

  return salesCommission;
}

export function useCurrentUser() {
  const stateId = useSelector((state: ReduxState) => {
    const { userId } = state.auth.profile || { userId: 'undefined' };
    return userId;
  });
  const impersonate = useContext(ImpersonateContext);
  const id = impersonate?.userId || stateId;

  return useEntry<EntityModels.User>({
    id,
    passive: !impersonate?.userId,
    entity: 'users',
  });
}

const selectPayoutRequest =
  (requestStoreKey?: string) => (entityData: EntityReduxState) => {
    return requestStoreKey
      ? entityData.indexedData && entityData.indexedData[requestStoreKey]
      : entityData.data;
  };

const nestAddresses: NestDefinition = [
  {
    key: 'payoutAddresses',
    entity: 'payoutAddresses',
  },
];
export function usePayoutRequest({
  requestStoreKey,
}: { requestStoreKey?: string } = {}) {
  const selectEntry = useMemo(
    () => selectPayoutRequest(requestStoreKey),
    [requestStoreKey]
  );

  const { request } = useFetchEntity({
    entity: 'payoutRequest',
  });

  // TODO: refactor selectEntry types to something more meaningful or useEntryProvider to useDataProvider or smth
  // @ts-ignore
  const entry = useEntryProvider<EntityModels.PayoutRequest>({
    entity: 'payoutRequest',
    selectEntry,
    nest: nestAddresses,
  });

  return {
    request,
    entry,
  };
}

const countriesQuery = { perPage: 300 };

export function useCountries() {
  return useEntries({ entity: 'countries', query: countriesQuery });
}

export function useLanguages() {
  return useEntries<EntityModels.Language>({
    entity: 'languages',
    query: countriesQuery,
  });
}

const regionsQuery = { with: 'countries' };

const deliveryRegionsNest: NestDefinition = [
  {
    key: 'countries',
    entity: 'deliveryRegionCountries',
    nest: [
      {
        getKey: (entry: EntityModels.DeliveryRegionCountry) => {
          return entry?.countryId;
        },
        key: 'country',
        entity: 'countries',
      },
    ],
  },
];

const emptyRegions: [] = [];

type DeliveryRegionNested = EntityModels.Nest<
  EntityModels.DeliveryRegion,
  {
    countries: Array<
      EntityModels.Nest<
        EntityModels.DeliveryRegionCountry,
        { country: EntityModels.Country }
      >
    >;
  }
>;

export function useDeliveryRegions() {
  const {
    request: { loaded, loading },
  } = useFetchEntity({ entity: 'countries', query: countriesQuery });

  const regionsData = useEntries<DeliveryRegionNested>({
    entity: 'deliveryRegions',
    query: regionsQuery,
    nest: deliveryRegionsNest,
  });

  const output = useMemo(() => {
    const request = {
      ...(regionsData.request || {}),
      loaded: regionsData.request?.loaded && loaded,
      loading: regionsData.request?.loading && loading,
    };
    return {
      ...regionsData,
      entries: request.loaded ? regionsData.entries : emptyRegions,
      request,
    };
  }, [loaded, loading, regionsData]);

  return output;
}

export function usePublisher({ ...props }) {
  return useEntry<EntityModels.Publisher>({ entity: 'publishers', ...props });
}

export function useShops({ ...props } = {}) {
  return useEntries<EntityModels.Shop>({ entity: 'shops', ...props });
}

const APP_BANNER_NOTIFICATIONS = [
  'spotify-stream-boosting',
  'subscription-renewal-failed',
  'payment-rescue-attempt',
  'yearly-subscription-renewal-disabled',
];

const countNotifications = (entries: EntityModels.UserNotification[]) =>
  entries.reduce((acc, entry) => (entry?.wasRead === false ? acc + 1 : acc), 0);

export function useUserNotifications({
  passive,
  all,
}: { all?: boolean; passive?: boolean } = {}) {
  const data = useEntries<EntityModels.UserNotification>({
    passive,
    entity: 'userNotifications',
  });
  const entries = useMemo(
    () =>
      data.entries.filter((n) =>
        all ? true : APP_BANNER_NOTIFICATIONS.indexOf(n.notification.type) < 0
      ),
    [data.entries]
  );
  const unreadCount = useMemo(() => countNotifications(entries), [entries]);
  return { ...data, entries, unreadCount };
}

export const useSelectImperonatedUser = () => {
  const userId = useSelector(
    (state: ReduxState) => state.ui?.impersonate?.userId
  );

  const user = useEntryProvider<EntityModels.User>({
    entity: 'users',
    id: userId,
  });

  return userId ? user : null;
};

type FilePreviewsContext = {
  createPreview: (file: File, id: string) => void;
  getPreview: (id: string) => string;
  destroyPreview: (id: string) => void;
};

const FilePreviewsContext = createContext<FilePreviewsContext | null>(null);

function useFilePreviewContext() {
  // reads from context
  // stores files on context state
  // retrieves previews by id?
  // has destroy function to clear preview

  const [previews, setPreviews] = useState<Record<string, string>>({});

  const createPreview = useCallback(
    (file, id) => {
      if (file) {
        const preview = URL.createObjectURL(file);
        setPreviews({
          ...previews,
          [id]: preview,
        });
      }
    },
    [previews]
  );

  const getPreview = useCallback(
    (id) => {
      return previews[id];
    },
    [previews]
  );

  const destroyPreview = useCallback(
    (id) => {
      const preview = previews[id];
      if (preview) {
        URL.revokeObjectURL(preview);
        setPreviews({ ...previews, [id]: undefined });
      }
    },
    [previews]
  );

  const value = useMemo(
    () => ({
      createPreview,
      getPreview,
      destroyPreview,
    }),
    [previews]
  );

  return value;
}

export const FilePreviewsProvider: React.FC = ({ children }) => {
  const value = useFilePreviewContext();

  return (
    <FilePreviewsContext.Provider value={value}>
      {children}
    </FilePreviewsContext.Provider>
  );
};

export const useFilePreviews = () => {
  const context = useContext(FilePreviewsContext);
  if (context === null) {
    throw new Error('FilePreviewsContext doesnt exist');
  }
  return context;
};

export type UploadableHandlers =
  | 'artistPageTourSplashImage'
  | 'artistImage'
  | 'artistFile'
  | 'releasePageFile'
  | 'releasePagesPressFile'
  | 'artistProfileImage';

// Dispatches action, fileId entity:id
// bind to post for request?
export function useUploadFile(
  handler: UploadableHandlers,
  handlerData?: any,
  fileUploadRequestId: string | number | undefined = undefined
) {
  const [internalFileId, setInternalFileId] = useState(fileUploadRequestId);
  const { create: sendToUpload, remove: deleteFile } =
    useEntityActions('filesManager');

  const uploadFile = useCallback(
    (file) => {
      const id = internalFileId || uuid();

      if (!internalFileId) {
        setInternalFileId(id);
      }

      sendToUpload({
        id,
        data: {
          handlerData,
          file,
          handler,
        },
      });

      // dispatch action
    },
    [internalFileId, handlerData]
  );

  const abortAndDeleteFile = useCallback((id) => {
    if (id) {
      deleteFile({
        id,
        data: {
          handlerData,
          handler,
        },
      });
    }
  }, []);
  const fileUpload = useFileUploadRequestProvider({
    entity: 'filesManager',
    id: internalFileId,
  });

  return {
    id: fileUpload?.fileId,
    request: fileUpload,
    abortAndDeleteFile,
    uploadFile,
  };
}
