import React from 'react';
import { useSelector } from 'react-redux';
import type { ReduxState } from 'imddata';
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { ProtectedRoute, FormWrapper } from './components';
import { Terms, BlockedCustomer, ProfileDeletion } from './screens';

const LOGGED_IN_AUTH_ROUTES = ['terms', 'profile-deletion', 'blocked'];

export const useLoggedAuthRoutesMatch = () => {
  return useRouteMatch(
    `(${LOGGED_IN_AUTH_ROUTES.map((route) => `/${route}`).join('|')})`
  );
};

export default function Auth() {
  const { state } = useLocation<{ from?: { pathname: string } }>();
  const loggedUser = useSelector((s: ReduxState) => s.auth);

  if (!loggedUser.data) {
    return (
      <Switch>
        <Route path="/terms" component={Terms} />

        <ProtectedRoute path="/profile-deletion/:code" render={() => null} />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route
        path="/profile-deletion/:code"
        render={() => (
          <FormWrapper loggedIn={true}>
            <ProfileDeletion />
          </FormWrapper>
        )}
      />
      <Route exact={true} path="/terms" component={Terms} />
      <ProtectedRoute
        path="/blocked"
        render={() => (
          <FormWrapper loggedIn={true}>
            <BlockedCustomer />
          </FormWrapper>
        )}
      />

      <Route
        path="/"
        render={() => {
          if (state && state.from && state.from.pathname !== '/') {
            return <Redirect to={state.from} />;
          }
          return <Redirect to="/not-found" />;
        }}
      />
    </Switch>
  );
}
