import React, { useEffect, useState } from 'react';
import { ConfirmationWindow, LinkButton, Button } from 'imdshared';
import type { EntityModels } from 'imddata';
import { useUpdateEntity } from 'imddata';
import { useTranslation } from 'react-i18next';

export default function PayInvoiceButton({
  invoice: { id, status, isPayable },
  style,
  className,
}: {
  style?: React.CSSProperties;
  className?: string;
  invoice: EntityModels.Invoice;
}) {
  const [confirmCancel, setConfirmCancel] = useState(false);
  const { t } = useTranslation();
  const {
    updateEntry,
    request: { updating, updated },
  } = useUpdateEntity({ id, entity: 'invoices' });

  const restartable =
    status === 'pending' ||
    status === 'legacy_pending' ||
    status === 'payment_failed' ||
    status === 'payment_timeout';

  useEffect(() => {
    if (updated) {
      setConfirmCancel(false);
    }
  }, [updated]);

  if (!isPayable && !restartable) return null;

  return (
    <>
      <ConfirmationWindow
        isOpen={confirmCancel}
        loading={updating}
        title={t('cancel-invoice')}
        message={t('invoice-cancelation-confirmation-message')}
        onRequestClose={() => {
          setConfirmCancel(false);
        }}
        onConfirm={() => {
          updateEntry({ data: { status: 'canceled' } });
        }}
      />
      <Button
        text={t('cancel')}
        showLoading={updating}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setConfirmCancel(true);
        }}
        style={{
          marginRight: 8,
        }}
        className={className}
      />
      <>
        {isPayable ? (
          <LinkButton
            to={`/account/invoices/${id}/pay`}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
            disabled={updating}
            primary="paid"
            text={t('pay')}
            style={style}
            className={className}
          />
        ) : restartable ? (
          <Button
            text={t('restart-payment')}
            showLoading={updating}
            primary={true}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              updateEntry({ data: { status: 'unpaid' } });
            }}
            style={style}
            className={className}
          />
        ) : null}
      </>
    </>
  );
}
